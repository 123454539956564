/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token") ?? "");
  const [userName, setUserName] = useState("");
  const cartSize = useSelector((state) => state.items.length);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      setUserName(`${decoded.firstName} ${decoded.lastName}`);
    }
  }, [token]);

  const handleLogout = () => {
    confirmDialog({
      message: "Are you sure you want to log out?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept: () => {
        localStorage.removeItem("token");
        setToken("");
        setUserName("");
        navigate("/admin-login");
      },
    });
  };

  const linkStyle = {
    color: "white",
    textDecoration: "none",
    cursor: "pointer",
    paddingBottom: "2px",
    borderBottom: "1px solid transparent",
  };

  const activeLinkStyle = {
    ...linkStyle,
    borderBottom: "1px solid rgb(250, 156, 38)",
  };

  const getLinkStyle = (path) =>
    location.pathname === path ? activeLinkStyle : linkStyle;

  const menubarItems = [
    { label: "Home", path: "/" },
    { label: "Gallery", path: "/gallery" },
    { label: "Cart", path: "/cart", isCart: isMobile },
    { label: "Contact Us", path: "/contact-us" },
  ].map((item) => ({
    ...item,
    template: (item) => (
      <div style={getLinkStyle(item.path)} onClick={() => navigate(item.path)}>
        {item.label}
        {item.isCart && ` (${cartSize})`}
      </div>
    ),
  }));

  const sidebarItems = [
    ...menubarItems,
    ...(token
      ? [
          { label: "Calculator", path: "/admin/calculator" },
          { label: "Items", path: "/admin/items" },
        ]
      : []),
  ];

  const start = (
    <img
      style={{ height: "50px", cursor: "pointer" }}
      src={require(`../logo.png`)}
      alt=""
      onClick={() => navigate("/")}
    />
  );

  const end = (
    <div className="flex gap-4 align-content-center">
      {!isMobile ? (
        <span
          className="flex align-items-center"
          style={getLinkStyle("cart")}
          onClick={() => navigate("cart")}
        >
          View Cart
          <span
            style={{ color: "rgb(250, 156, 38)" }}
            className="pi pi-shopping-cart ml-3 mr-2"
          ></span>
          <span style={{ color: "rgb(250, 156, 38)" }}>{cartSize}</span>
        </span>
      ) : null}
      <span className="flex align-items-center">
        <span
          style={{ color: "#fff" }}
          className="pi pi-phone ml-3 mr-2"
        ></span>
        <a style={linkStyle} href="tel:+94718021976">
          +94 71 802 1976
        </a>
      </span>
      {isMobile || token ? (
        <Button
          icon="pi pi-bars"
          className="p-button-text btn-text-white cursor-pointer"
          unstyled={true}
          onClick={() => setSidebarVisible(true)}
        />
      ) : null}
    </div>
  );

  return (
    <div className="header-container">
      <ConfirmDialog />
      <div
        className="w-100"
        style={{ height: "38px", backgroundColor: "#F1F1F1" }}
      >
        <div className="content-wrapper flex justify-content-end gap-5 align-items-center h-full p-0">
          <div className="flex gap-2">
            <span style={{ lineHeight: "0.5rem" }} className="text-sm">
              A
            </span>
            <span style={{ lineHeight: "0.5rem" }}>A</span>
            <span style={{ lineHeight: "0.5rem" }} className="text-lg">
              A
            </span>
          </div>
          <div className="flex gap-2">
            <span>English</span>
            <span>සිංහල</span>
          </div>
        </div>
      </div>
      <div className="flex lg:px-5 px-2 header">
        <Menubar
          start={start}
          model={isMobile ? [] : menubarItems}
          end={end}
          className="content-wrapper menubar"
        />
      </div>
      <Sidebar
        visible={sidebarVisible}
        onHide={() => setSidebarVisible(false)}
        position="right"
        style={{ overflow: "hidden" }}
      >
        <ul className="p-menu-list" style={{ padding: 0, margin: 0 }}>
          {sidebarItems.map((item, index) => (
            <li
              key={index}
              className="cursor-pointer p-3 mt-2"
              onClick={() => {
                setSidebarVisible(false);
                navigate(item.path);
              }}
            >
              {item.label}
              {item.isCart && ` (${cartSize})`}
            </li>
          ))}
        </ul>
        {token && (
          <div className="border-top-1 surface-border flex align-items-center justify-content-between pt-2 px-1">
            <div>{userName}</div>
            <Button
              icon="pi pi-sign-out"
              className="p-button-secondary p-button-text"
              onClick={handleLogout}
            />
          </div>
        )}
      </Sidebar>
    </div>
  );
};

export default Header;
