// language.js

const language = "en";

const map = {
  Islandwide: {
    en: "Islandwide",
    si: "සියලු දිස්ත්‍රික්කයන්",
  },
  Service: {
    en: "Service",
    si: "සේවා",
  },
  "My Cart": {
    en: "My Cart",
    si: "මගේ කරත්තය",
  },
  "Additional Services": {
    en: "Additional Services",
    si: "අමතර සේවාවන්",
  },
  "Order Summary": {
    en: "Order Summary",
    si: "ඇණවුම් සාරාංශය",
  },
  Step: {
    en: "Step",
    si: "පියවර",
  },
  "Fixing Required": {
    en: "Fixing Required",
    si: "විසර්ජන අවශ්‍යයි",
  },
  "Transport Required": {
    en: "Transport Required",
    si: "ප්‍රවාහන අවශ්‍යයි",
  },
  "Total Quantities": {
    en: "Total Quantities",
    si: "මුළු ප්‍රමාණය",
  },
  "Pole Width": {
    en: "Pole Width",
    si: "කූඩු පළල",
  },
  "Total Pleats": {
    en: "Total Pleats",
    si: "මුළු බෙල්ල",
  },
  "Fixing Cost": {
    en: "Fixing Cost",
    si: "විසර්ජන මිල",
  },
  "Transportation Cost": {
    en: "Transportation Cost",
    si: "ප්‍රවාහන ගාස්තු",
  },
  Total: {
    en: "Total",
    si: "මුළු එකතුව",
  },
  "Please fill your details to get your quotation.": {
    en: "Please fill your details to get your quotation.",
    si: "ඔබේ මිලගණන් ලබා ගැනීමට තොරතුරු සම්පූර්ණ කරන්න.",
  },
  "You will receive the quote via an Email": {
    en: "You will receive the quote via an Email",
    si: "ඔබට මිලගණන් ඊ-මේල් එකක් මගින් ලැබේවි",
  },
  "Get a quotation for the items in the cart": {
    en: "Get a quotation for the items in the cart",
    si: "කරත්තයේ අයිතම සඳහා මිල ගණන් ලබා ගන්න",
  },
  "Clear Cart": {
    en: "Clear Cart",
    si: "කරත්තය හිස් කරන්න",
  },
  "Please wait ...": {
    en: "Please wait ...",
    si: "කරුණාකර රැදී සිටින්න...",
  },
  "Item Code": {
    en: "Item Code",
    si: "අයිතම කේතය",
  },
  "Unit Price": {
    en: "Unit Price",
    si: "ඒකක මිල",
  },
  Quantity: {
    en: "Quantity",
    si: "ප්‍රමාණය",
  },
  Type: {
    en: "Type",
    si: "වර්ගය",
  },
  Width: {
    en: "Width",
    si: "පළල",
  },
  Height: {
    en: "Height",
    si: "උස",
  },
  Pleats: {
    en: "Pleats",
    si: "බෙල්ල",
  },
  Pole: {
    en: "Pole",
    si: "කූඩු",
  },
  Yes: {
    en: "Yes",
    si: "ඔව්",
  },
  No: {
    en: "No",
    si: "නැත",
  },
  Title: {
    en: "Title",
    si: "ශීර්ෂය",
  },
  "First Name": {
    en: "First Name",
    si: "මුල් නම",
  },
  "Last Name": {
    en: "Last Name",
    si: "අවසන් නම",
  },
  "Telephone / Whatsapp Number": {
    en: "Telephone / Whatsapp Number",
    si: "දුරකථන / Whatsapp අංකය",
  },
  Email: {
    en: "Email",
    si: "ඊ-මේල්",
  },
  "Address Line 1": {
    en: "Address Line 1",
    si: "ලිපිනය 1",
  },
  "Address Line 2": {
    en: "Address Line 2",
    si: "ලිපිනය 2",
  },
  "Get a Quotation": {
    en: "Get a Quotation",
    si: "මිල ගණන් ලබා ගන්න",
  },
  Cart: {
    en: "Cart",
    si: "කරත්තය",
  },
  Distance: {
    en: "Distance",
    si: "දුර",
  },
};

export function t(text) {
  return map[text] && map[text][language] ? map[text][language] : text;
}
