import React from "react";
import { createRoot } from "react-dom/client";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "primereact/resources/themes/mdc-light-deeppurple/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./assets/css/custom.css";
import "style.css";

import Home from "views/home";
import Gallery from "views/gallery";
import Cart from "views/cart";
import Item from "views/Item";
import ScrollToTop from "./components/ScrollToTop";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";

import Footer from "components/footer";
import Header from "components/header";
import ContactUs from "views/contact-us";
import AdminCart from "views/admin-cart";
import Items from "views/items";
import Calculator from "views/calculator";
import Login from "views/login";

const container = document.getElementById("root");
const root = createRoot(container);

const token = localStorage.getItem("token") ?? "";

// ProtectedRoute Component Inline
const ProtectedRoute = ({ children }) => {
  return token ? children : <Navigate to="/admin-login" />;
};

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <ScrollToTop>
          <Header />
          <Routes>
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/cart" element={token ? <AdminCart /> : <Cart />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/item/:id" element={<Item />} />
            <Route path="/admin-login" element={<Login />} />
            <Route
              path="/admin/calculator"
              element={
                <ProtectedRoute>
                  <Calculator />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/items"
              element={
                <ProtectedRoute>
                  <Items />
                </ProtectedRoute>
              }
            />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Home />} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </Router>
    </PersistGate>
  </Provider>
);
